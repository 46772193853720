<template lang="pug">
  section
    Card.m-b-25(:title="$t('theme preferences')" dis-hover)
      div(slot="extra")
        Spin(fix)
          Icon(type="ios-loading" size=18 class="demo-spin-icon-load")
      
      RadioGroup.with-thumb.padding-25.full-width(v-model="theme" @on-change="onChange")
        Row(:gutter="30")
          iCol.m-b-30(span="8" v-for="(item, index) in themes" :key="index")
            Radio(:label="item.value" border)
              span {{ item.label }} ({{ item.value }})
              .thumb(:style="{ backgroundImage: `url(${item.thumb})`}")
</template>

<script>
export default {
  name: 'AppearanceView',
  components: {},
  data () {
    return {
      theme: 'light',
      themes: [
        { value: 'light', label: 'vmc 浅色主题', thumb: 'https://github.githubassets.com/images/modules/settings/color_modes/light_preview.svg' },
        { value: 'dark', label: 'vcm 深色主题', thumb: 'https://github.githubassets.com/images/modules/settings/color_modes/dark_preview.svg' },
        { value: 'shine', label: '发光', thumb: '' },
        { value: 'fluent', label: '流畅', thumb: '' },
        { value: 'capture', label: '捕获的动作', thumb: '' },
      ]
    }
  },
  computed: {},
  methods: {
    onChange() {
      console.log(this.theme)
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.with-thumb {
  .ivu-radio-wrapper {
    width: 100%; height: auto;line-height: 38px;
    padding: 52% 10px 0; margin: 0;
    overflow: hidden;
    .thumb {
      background-repeat: no-repeat; background-position: center center;
      background-size: cover;
      position: absolute; top: 0; left: 0; bottom: 38px;
      width: 100%;
      border-bottom: 1px solid #dcdee2;
    }
  }
}

.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from { transform: rotate(0deg);}
  50%  { transform: rotate(180deg);}
  to   { transform: rotate(360deg);}
}
</style>
